import React, {useState} from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OnSite from './pages/On-site';
import About from './pages/About';
import Home from './pages/Home';
import Remote from './pages/Remote';
import Contact from './pages/Contact';
import Translation from "./pages/Translations"
import Languages from "./pages/Languages";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function PageContainer() {
  const [currentPage, setCurrentPage] = useState('home');

  // This method is checking to see what the value of `currentPage` is. Depending on the value of currentPage, we return the corresponding component to render.
  const renderPage = () => {
    if (currentPage === 'home') {
      return <Home />;
    }
    if (currentPage === 'about') {
      return <About />;
    }
    if (currentPage === 'onsite') {
      return <OnSite />;
    }
    if (currentPage === 'contact') {
      return <Contact />;
    }
    if (currentPage === 'translation') {
      return <Translation />;
    }
    if (currentPage === 'remote') {
        return <Remote />;
      }
      if (currentPage === 'languages') {
        return <Languages />;
      }
  };

  const handlePageChange = (page) => setCurrentPage(page);

  return (
    <div className="App">
      {/* We are passing the currentPage from state and the function to update it */}
      <Header currentPage={currentPage} handlePageChange={handlePageChange} />
      {/* Here we are calling the renderPage method which will return a component  */}
      {renderPage()}
      <Footer />
    </div>
  );
}