import React, { useEffect, useState } from 'react';

export default function Languages() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="container">
      <div className="about pb-5">
        <div className={`text_container ${isVisible ? 'show' : ''}`}>
          <div className="pt-5 page_title text-center">Languages Offered</div>
          <div className="about_text">
            At Interpreters Connect, we offer interpreting services in all languages. Below is a sample of the most in-demand languages we can assist with:
            <span>
              <div className="language_lists">
                <ul className="language_list">
                  <li>English</li>
                  <li>Spanish</li>
                  <li>Arabic</li>
                  <li>Chinese (Mandarin & Cantonese)</li>
                  <li>French</li>
                  <li>German</li>
                  <li>Russian</li>
                  <li>Portuguese</li>
                  <li>Italian</li>
                  <li>Vietnamese</li>
                </ul>
                <ul className="language_list">
                  <li>Korean</li>
                  <li>Tagalog</li>
                  <li>Haitian Creole</li>
                  <li>American Sign Language (ASL)</li>
                  <li>Polish</li>
                  <li>Japanese</li>
                  <li>Greek</li>
                  <li>Hebrew</li>
                  <li>Turkish</li>
                  <li>Hindi</li>
                  <li>Swahili</li>
                </ul>
              </div>
              <p>And many more...</p>
              <p>
                If the language you need isn't listed, don’t hesitate to contact us. We offer interpreting services in all languages to meet your needs.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
