import React from 'react';
import Card from '../components/Card'
import Hero from '../components/Hero';
export default function Home({currentPage, handlePageChange}) {
  
  return (
    <div className='home_body'>
    <div className='text-center pb-5'>
      <Hero />
      <div className='container'>
        <Card />
         </div>
    </div>
    </div>
  );
}
