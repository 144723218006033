import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [isVisible, setIsVisible] = useState(false);
const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const form = useRef(null);

  // State to store input values
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    message: "",
  });

  // Function to handle capitalization and updating state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.charAt(0).toUpperCase() + value.slice(1),
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_90nyfqd", // Replace with your Service ID
        "template_w5pkqio", // Replace with your Template ID
        form.current, // Pass the form reference
        "jJkM8VOlYqqZFfZBQ" // Replace with your Public Key
      )
      .then(
        () => {
          console.log("SUCCESS!", form.current);
          setIsSubmitted(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div
    className={`contact_container d-flex flex-column align-items-center justify-content-center ${isVisible ? "visible" : "hidden"}`}
  >
    {!isSubmitted && (
    <form ref={form} onSubmit={sendEmail}>
        <div className="pt-5 page_title text-center">Contact Us</div>
        <div className="input_text mx-auto mb-3">
          <label className="form-label">First Name: </label>
          <input
            name="first_name"
            type="text"
            className="form-control"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input_text mx-auto mb-3">
          <label className="form-label">Last Name: </label>
          <input
            name="last_name"
            type="text"
            className="form-control fs-6"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input_text mx-auto mb-3">
          <label className="form-label">Business/Organization Name: </label>
          <input
            name="company_name"
            type="text"
            className="form-control"
            value={formData.company_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input_text mx-auto mb-3">
          <label className="form-label">Email: </label>
          <input
            name="email"
            type="email"
            className="form-control no-bold"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mx-auto input_text mb-3">
          <label className="form-label">Message</label>
          <textarea
            name="message"
            className="form-control"
            id="textarea"
            rows="3"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          value="Send"
          className="input_text my-5 ml-auto btn bg-#023020"
          style={{ backgroundColor: "#023020", color: "white" }}
        >
          Submit
        </button>
        </form>
        )}
        {isSubmitted && (
          <div className="thank_you">
            Thank you for Contacting Us! We will reach out to you shortly.
          </div>
        )}
      </div>
  



);
}
