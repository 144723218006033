import React, {useEffect, useState} from 'react';






export default function OnSite() {
  const [isVisible,setIsVisible] = useState(false);

useEffect(() => {
setIsVisible(true);

}, []);

  return (
    <div className='container'>
    <div className='about pb-5'>
      
         
         <div className={`text_container ${isVisible ? 'show' : ''}`}>
         <div className="pt-5 page_title text-center">On-Site Interpreting</div>
          <div className='about_text'>
          At Interpreters Connect, we offer professional on-site interpreting services for a wide range of industries. Whether you’re hosting a meeting, conference, legal proceeding, or healthcare appointment, our interpreters are ready to ensure seamless communication in any setting.
          We provide both simultaneous and consecutive interpreting services to meet your specific needs:
<span>
  <ul className='text_list'>
  <li>
  <span className='list_title'>Simultaneous Interpreting:</span><br></br> Our interpreters will translate speech in real-time, allowing for a smooth flow of communication without interruptions. This service is ideal for conferences, large meetings, and events where quick, uninterrupted translation is essential.
  </li>
  <li>
  <span className='list_title'>Consecutive Interpreting:</span><br></br>  For settings where accuracy and detail are paramount, our interpreters will wait for the speaker to finish a sentence or idea before providing the interpretation. This method is often used in legal, medical, and one-on-one meetings, ensuring precise and accurate communication.
  </li>


  </ul>
  </span>






At Interpreters Connect, we aim to exceed expectations by delivering high-quality, reliable services with a touch of innovation, all while keeping our processes fast and straightforward. Your communication is our priority.

</div>
 </div>

    </div>
    </div>
  );
}

