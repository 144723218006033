//import pic1 from "../images/online-reputation-management.jpg"
import pic2 from "../images/professional.jpg"
import pic3 from "../images/onsite.jpg"
// import pic4 from "../images/social-media-marketing.jpg"
import pic5 from "../images/translation.jpg"
import pic6 from "../images/collab_photo.jpg"

const services = [
    {   
        id: 1,
        name: 'On-site Interpreting',
        image: pic3,
        alt: 'professional',
        Description: ' Receive professional and experienced interpretation services on-site with world class experienced interpreters'

    },
    {   
        id: 1,
        name: 'Remote Interpreting',
        image: pic2,
        alt: 'professional',
        Description: ' Receive professional and experienced translation services for any remote interpretation needs with world class interpreters'

    },
    {   
        id: 1,
        name: 'Document Translation',
        image: pic5,
        alt: 'translation',
        Description: ' Receive professional and precise translation for any document'

    },
    {   
        id: 1,
        name: 'Professional Interpreters',
        image: pic6,
        alt: 'professional',
        Description: ' Our interpreters offer the best and most accurate translation services proven through an extensive track record and testimonials'

    },




]

export default services;