import React from "react";
import logo from "../assets/images/logo.png";
// Here we are using object destructuring assignment to pluck off our variables from the props object
// We assign them to their own variable names
function Header({ currentPage, handlePageChange }) {


  return (
    <>
      <div className="header">
        <div className="headerContact">
          <div className="m-3 text-decoration-none">
            <a href="tel:407-579-8993">720-965-7473</a> |{"  "}
            <a href="mailto:info@interpretersconnect.com">
              info@interpretersconnectllc.com
            </a>
          </div>
        </div>
        <div className="logo_container">
          <img className="logo" alt="logo" src={logo}></img>
        </div>
      </div>
      <div className="w-100 border-bottom navbar d-flex justify-content-center">
        <nav className=" navbar navbar-expand-lg navbar-light ">
          <button
            className=" navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className=" menu-icon navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar collapse navbar-collapse"
            id="navbarNavAltMarkup"
          >
            <div className="align-items-center h5 navbar-nav w-100">
              <a
                onClick={() => handlePageChange("home")}
                className={
                  currentPage === "home"
                    ? "nav-item nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#home"
              >
                Home
              </a>
              <a
                onClick={() => handlePageChange("about")}
                className={
                  currentPage === "about"
                    ? "nav-item nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#about"
              >
                About Us
              </a>
              <a
                onClick={() => handlePageChange("languages")}
                className={
                  currentPage === "languages"
                    ? "nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#languages"
              >
                Languages
              </a>
              <a
                onClick={() => handlePageChange("onsite")}
                className={
                  currentPage === "onsite"
                    ? "nav-link nav-item active me-3"
                    : "nav-link me-3"
                }
                href="#onsite"
              >
                On-Site
              </a>
              <a
                onClick={() => handlePageChange("remote")}
                className={
                  currentPage === "remote"
                    ? "nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#remote"
              >
                Remote
              </a>
              <a
                onClick={() => handlePageChange("translation")}
                className={
                  currentPage === "translation"
                    ? "nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#translation"
              >
                Translations
              </a>


              <a
                onClick={() => handlePageChange("contact")}
                className={
                  currentPage === "contact"
                    ? "nav-link active me-3"
                    : "nav-link me-3"
                }
                href="#contact"
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;
