import React, {useEffect, useState} from 'react';






export default function Remote() {
  const [isVisible,setIsVisible] = useState(false);

useEffect(() => {
setIsVisible(true);

}, []);

  return (
    <div className='container'>
    <div className='about pb-5'>
      
         
         <div className={`text_container ${isVisible ? 'show' : ''}`}>
         <div className="pt-5 page_title text-center">Remote Interpreting</div>
          <div className='about_text'>
          At Interpreters Connect, we offer remote interpreting services that allow you to communicate effectively no matter where you are. Whether you're conducting virtual meetings, conferences, or appointments, our remote interpreting solutions are designed to meet your needs across a wide range of platforms.

We provide both simultaneous and consecutive interpreting remotely, and we’re equipped to work with many platforms, including:
<span>
  <ul className='remote_text_list'>
  <li>
 Zoom
  </li>
  <li>
Google Meet
  </li>
  <li>
  Microsoft Teams
  </li>
  <li>
Parrot
  </li>
  <li>
  Skype
  </li>
  <li>
  Webex
  </li>
  <li>
  GoToMeeting
  </li>
  <li>
  RingCentral
  </li>
  <li>
 BlueJeans
  </li>
  <li>
  Over The Phone
  </li>
  </ul>

       ...and many more. <br>
       </br>
  </span>






At Interpreters Connect, we aim to exceed expectations by delivering high-quality, reliable services with a touch of innovation, all while keeping our processes fast and straightforward. Your communication is our priority.

</div>
 </div>

    </div>
    </div>
  );
}
