import React from 'react';
export default function Footer() {
  return (
    <div className="footer">
    <a className='footer-icon'
       href="#!"
       role="button"
       ><i className="fab fa-facebook-f"></i></a>
    <a className='footer-icon'
       href="facebook.com"
       role="button"
       ><i className="fab fa-instagram"></i></a>
    <a className='footer-icon'
       href="instagram.com"
       role="button"
       ><i className="fab fa-google"></i></a>
        <a className='footer-icon'
       href="google.com"
       role="button"
       ><i className="fas fa-envelope"></i></a>
  </div>
  );
}