import React, { useEffect, useState } from "react";

export default function Translations() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="container">
      <div className="about pb-5">
        <div className={`text_container ${isVisible ? "show" : ""}`}>
          <div className="pt-5 page_title text-center">
            Translation Services
          </div>
          <div className="about_text">
            At Interpreters Connect, we offer high-quality document translation
            services tailored to meet the needs of businesses, individuals, and
            organizations across various industries. Whether you need to
            translate legal contracts, medical records, marketing materials, or
            any other type of document, our team of professional translators
            ensures accurate and timely translations every time.
            <br></br>
            Document Translation
            <br></br>
            Document translation is one of our most in-demand services, and for
            good reason. We understand the importance of precision and clarity
            when it comes to important documents, and we are committed to
            providing translations that are not only accurate but culturally
            appropriate for your target audience.
            <span>
              <ul className="text_list">
                <li>
                  <span className="list_title">Legal Documents:</span>
                  <br></br> We specialize in translating contracts, agreements,
                  court documents, and more. Our legal translators ensure that
                  your documents are accurately interpreted, maintaining the
                  integrity of the original text.
                </li>
                <li>
                  <span className="list_title">Medical Documents:</span>
                  <br></br> Our team is well-versed in medical terminology,
                  making us the perfect choice for translating medical records,
                  patient forms, prescriptions, and more with the utmost
                  accuracy.
                </li>
                <li>
                  <span className="list_title">Business Documents:</span>
                  <br></br> From financial statements and reports to marketing
                  brochures and company policies, we provide seamless
                  translation for all types of business documents.
                </li>
                <li>
                  <span className="list_title">Certified Translations:</span>
                  <br></br> For documents that require certification, such as
                  birth certificates, immigration papers, and legal filings, we
                  provide certified translations that meet all legal and
                  official standards
                </li>
              </ul>
            </span>
            We take pride in delivering document translations that are not only accurate but also timely, helping you meet deadlines and communicate clearly across languages. No matter what type of document you need translated, Interpreters Connecthas the expertise to ensure your message is understood in any language.
          </div>
        </div>
      </div>
    </div>
  );
}
