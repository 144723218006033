import React from 'react'
import services from '../assets/data/services'


function Card({currentPage, handlePageChange}) {
  const service1 = services[0];
  const service2 = services[1];
  const service3 = services[2];
  const service4 = services[3];

  return (
    <div className='container'>

 <div className="card-container">
  
  <img className="cardImg" src={service1.image} alt="prof" />
  <div className="card-body">
    <div className="cardTitle">{service1.name}</div>
    <p className="card-text"> {service1.Description}</p>
  </div>
</div>

<div className="card-container">
  <div className="card-body">
    <div className="cardTitle">{service2.name}</div>
    <p className="card-text">{service2.Description}</p>
  </div>
  <img className="cardImg" src={service2.image} alt="pic" />
</div>

<div className="card-container">
  <img className="cardImg" src={service3.image} alt="collab" />
  <div className="card-body">
    <div className="cardTitle">{service3.name}</div>
    <p className="card-text">{service3.Description}</p>
  </div>
</div>
<div className="card-container">
  <div className="card-body">
    <div className="cardTitle">{service4.name}</div>
    <p className="card-text">{service4.Description}</p>
  </div>
  <img className="cardImg" src={service4.image} alt="Card" />
</div>
</div>

  )
}
export default Card;