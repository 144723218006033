import React from 'react'
import hero from "../assets/images/hero3.jpg"
export default function Hero
() {
  return (
    <div className=''>
<img className='hero' alt='hero' src={hero}/>
    </div>
  )
}
