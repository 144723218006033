import React, {useEffect, useState} from 'react';






export default function About() {
  const [isVisible,setIsVisible] = useState(false);

useEffect(() => {
setIsVisible(true);

}, []);

  return (
    <div className='container'>
    <div className='about pb-5'>
      
         
         <div className={`text_container ${isVisible ? 'show' : ''}`}>
         <div className="pt-5 page_title text-center">About Us</div>
          <div className='about_text'>
          {' '}At Interpreters Connect, we are revolutionizing the interpreting industry with a fresh, modern approach. As a new, forward-thinking company, we pride ourselves on breaking away from traditional methods and offering a level of convenience and efficiency that today’s fast-paced world demands. Our mission is to bridge communication gaps with ease, speed, and precision, while providing exceptional service to industries that require seamless multilingual communication.

We understand that the needs of the modern world are evolving. That’s why we’ve made booking our services quick, simple, and responsive. Whether you need remote interpreting, in-person interpreting, certified translations, ASL services, or web translations, our team is ready to accommodate your every request, ensuring smooth, effective communication every time.

We specialize in a range of sectors, including:
<span>
  <ul className='text_list'>
  <li>
  Law Firms
  </li>
  <li>
  Healthcare & Pharmaceutical
  </li>
  <li>
  Colleges & Universities
  </li>
  <li>
  Corporate & Financial
  </li>
  <li>
  Government & Military
  </li>
  <li>
  Legal Services
  </li>
  <li>
  Meeting Conventions
  </li>

  </ul>
  </span>






At Interpreters Connect, we aim to exceed expectations by delivering high-quality, reliable services with a touch of innovation, all while keeping our processes fast and straightforward. Your communication is our priority.

</div>
 </div>

    </div>
    </div>
  );
}
